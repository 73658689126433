<template>
  <v-card :loading="loading">
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-card-title class="grid-close">
      <span class="headline">Nuevo motivo de creación</span>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        :loading="loading"
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                outlined
                :loading="loading"
                :rules="[rules.required]"
                rounded
                label="Motivo de creación"
                v-model="item.name"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-textarea
                outlined
                :rules="[rules.required]"
                rounded
                :loading="loading"
                rows="3"
                label="Descripción"
                v-model="item.description"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-container fluid>
        <v-row class="pa-0 ma-0">
          <v-col cols="6" sm="6" md="6">
            <v-row justify="start">
              <v-switch v-model="item.active" label="Activo"></v-switch>
            </v-row>
          </v-col>

          <v-col cols="6" sm="6" md="6">
            <v-row justify="end">
              <v-btn
                @click="reason ? update() : save()"
                class="save-btn mt-4"
                color="primary"
                >Guardar</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
  
  <script>
import { db } from "@/firebase";
// import Lottie from "../../../components/Lottie";
// import * as animationData from "../../../assets/ochoColor.json";
// import moment from "moment-timezone";
// import "moment/locale/es";
// moment.locale("es");
import { mapState } from "vuex";

export default {
  name: "add-reason",
  props: ["reason"],

  data() {
    return {
      item: {
        active: true,
      },

      rules: {
        required: (value) => !!value || "Obligatorio",
      },
      snackbar: false,
      snackbarText: "",

      loading: true,
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    // handleAnimation: function (anim) {
    //   this.anim = anim;
    //   anim.setSpeed(this.animationSpeed);
    // },

    async update() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        await db
          .collection("couponCreationReasons")
          .doc(this.reason[".key"])
          .update({
            name: this.item.name,
            description: this.item.description,
            active: this.item.active,
            modifiedAt: new Date(),
            modifiedBy: this.user[".key"],
          })
          .then(() => {
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText = err.message;
            this.snackbar = true;
            this.loading = false;
          });
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        await db
          .collection("couponCreationReasons")
          .add({
            name: this.item.name,
            description: this.item.description,
            active: this.item.active,
            createdAt: new Date(),
            createdBy: this.user[".key"],
            deleted: false,
          })
          .then(() => {
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText = err.message;
            this.snackbar = true;
            this.loading = false;
          });
      }
    },
  },
  async mounted() {
    if (this.reason) {
      this.item = Object.assign({}, this.reason);
    }
    
    this.loading = false;
  },
};
</script>
  
 